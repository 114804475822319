"use client";

import { Footer } from "@/components/shared/footer/Footer";
import { HomeMainWrapper } from "@/app/(pages)/page.styles";
import {
  HomeBackgroundContainer,
  HomeContentContainer,
  HomeHeaderContainer,
  HomePricing,
  HomeResponsiveContainer,
} from "@/app/(body)/home.styles";
import TabbedContent from "@/components/home/TabbedContent";
import Advertising from "@/components/home/Advertising";
import { Workflow } from "@/components/home/Workflow";
import PricingBody from "./pricing/PricingBody";
import { Signup } from "@/components/home/Signup";
import { Header } from "@/components/home/Header";

const HomeBody = () => {
  return (
    <HomeMainWrapper
      data-testid="dti-landingArea"
      data-analytics="landing-area"
    >
      <HomeResponsiveContainer maxWidth="xl">
        <HomeHeaderContainer>
          <Header />
        </HomeHeaderContainer>
        <HomeContentContainer>
          <Advertising />
        </HomeContentContainer>
      </HomeResponsiveContainer>

      <HomeBackgroundContainer $color="#252629">
        <HomeResponsiveContainer maxWidth="xl">
          <HomeContentContainer>
            <Workflow />
          </HomeContentContainer>
        </HomeResponsiveContainer>
      </HomeBackgroundContainer>
      <HomeResponsiveContainer maxWidth="xl">
        <HomeContentContainer $hideMobile={true}>
          <TabbedContent />
        </HomeContentContainer>
      </HomeResponsiveContainer>
      <HomePricing>
        <PricingBody />
      </HomePricing>
      <HomeBackgroundContainer $background="linear-gradient(0deg, #C8B8FF 0%, #C8B8FF 100%), #E87BB1">
        <HomeResponsiveContainer maxWidth="xl">
          <Signup />
          <Footer />
        </HomeResponsiveContainer>
      </HomeBackgroundContainer>
    </HomeMainWrapper>
  );
};

export default HomeBody;
